import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {

  static readonly LANG = 'lang_key';

  constructor(private translateService: TranslateService) {
  }

  setLanguage(lang: any) {
    this.translateService.use(lang)
    localStorage.setItem(InternationalizationService.LANG, lang);
  }

  getLanguage(){
    return this.translateService.currentLang;
  }
}
